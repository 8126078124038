/**
 * Prevent double slash errors in url
 * @param str
 * @returns {string}
 */
export const removeDoubleSlashFromUrl = str => {
  const hasProtocol = str.indexOf('https://') >= 0
  const cleanStr = hasProtocol ? str.replace(/^https?:\/\//, '') : str
  return `${hasProtocol ? 'https://' : ''}${cleanStr.replace('//', '/')}`
}
