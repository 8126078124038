import React, { Children, FC, ReactElement } from 'react'

import styled from 'styled-components'
import { UserInterface } from '..'

import { IWheel } from './type'
import { ButtonLink, Flex, Svg } from '../index'

/**
 * Style
 */

const Grid = styled.div`
  width: 100%;
  padding-top: 100%;
  position: relative;
`

const WheelContainer = styled(props => <div {...props} />)`
  width: 93vw;
  max-width: 700px;
  min-width: 300px;
  background-image: url('${process.env
    .GATSBY_CDN_HOST}/components/wheel/wheel_large-2x.webp');
  opacity: 1;

  font-family: ${UserInterface.fonts.family.poppins};
  font-weight: 600;
  background-size: 80%;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;

  z-index: 3;
  list-style: none;

  &:before {
    content: '';

    background-size: 88%;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: -1;

    @media screen and (min-width: ${UserInterface.breakpoints.table}) {
      background-size: 80%;
    }
  }
`

const WheelMainText = styled(props => <p {...props} />)`
  position: absolute;
  left: 50%;
  top: 51%;
  transform: translate(-50%, -50%);
  z-index: 1;
  width: 300px;
  text-align: center;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  transition-delay: 0.15s;
  ${({ $isShowing }) => $isShowing && 'opacity: 1; transition-delay: 0;'};

  @media screen and (min-width: ${UserInterface.breakpoints.extraSmallMobile}) {
    font-size: 1.4rem;
    line-height: 2.2rem;
  }

  @media screen and (min-width: ${UserInterface.breakpoints.smallMobile}) {
    font-size: 1.5rem;
    line-height: 2.4rem;
  }

  @media screen and (min-width: ${UserInterface.breakpoints.table}) {
    font-size: 1.9rem;
    line-height: 2.7rem;
    width: 380px;
  }
`

const ButtonsContainer = styled(props => <Flex {...props} />)`
  flex-direction: row;
  justify-content: center;
  margin-bottom: 2rem;

  @media screen and (min-width: ${UserInterface.breakpoints.smallMobile}) {
    margin-top: 1rem;
  }

  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    display: none;
  }
`

const ButtonContainer = styled.div`
  border: 1px solid ${UserInterface.colors.middleGrey};
  border-radius: 50px;
  padding: 0.6rem;
  cursor: pointer;
  margin: 0 0.2rem;

  &:hover {
    box-shadow: 0 1px 3px 0 ${UserInterface.colors.middleGrey};
  }

  svg {
    width: 13px;
  }

  @media screen and (min-width: ${UserInterface.breakpoints.smallMobile}) {
    padding: 0.9rem;

    svg {
      width: 13px;
    }
  }

  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    padding: 0.9rem;

    svg {
      width: 18px;
    }
  }
`

const MainContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  padding: 4rem 0 9rem 0;
  justify-content: center;

  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    overflow: unset;
  }

  @media screen and (min-width: ${UserInterface.breakpoints.table}) {
    height: 95vh;
  }
`

const BlobContainer = styled.div`
  width: 200px;
  overflow: hidden;
  position: absolute;

  ${({ src }) => `background: url(${src}) no-repeat`};

  &.blob1 {
    border-radius: 21% 83% 57% 74% / 85% 74% 53% 35%;
    left: -35px;
    top: -22px;
    height: 184px;
    width: 219px;
    display: none;

    @media screen and (min-width: 750px) {
      height: 209px;
      width: 237px;
      display: block;
    }

    @media screen and (min-width: ${UserInterface.breakpoints.smallMobile}) {
      height: 225px;
      width: 268px;
    }

    @media screen and (min-width: 1050px) {
      height: 260px;
      width: 310px;
    }

    @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
      height: 320px;
      width: 370px;
    }

    @media screen and (min-width: ${UserInterface.breakpoints.table}) {
      height: 365px;
      width: 425px;
    }
  }

  &.blob2 {
    border-radius: 156% 83% 164% 89% / 140% 105% 80% 137%;
    right: -30px;
    top: -4%;
    height: 214px;
    width: 179px;
    display: none;

    @media screen and (min-width: 750px) {
      top: 0;
      height: 234px;
      width: 195px;
      display: block;
    }

    @media screen and (min-width: ${UserInterface.breakpoints.smallMobile}) {
      height: 270px;
      width: 220px;

      top: 6%;
      right: -50px;
    }

    @media screen and (min-width: 1050px) {
      height: 310px;
      width: 260px;
    }

    @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
      height: 380px;
      width: 330px;
    }

    @media screen and (min-width: ${UserInterface.breakpoints.table}) {
      height: 430px;
      width: 390px;
    }
  }

  &.blob3 {
    border-radius: 190% 108% 74% 33% / 224% 176% 48% 78%;
    left: 20%;
    bottom: -35px;
    height: 205px;
    width: 230px;
    display: none;

    @media screen and (min-width: 750px) {
      left: 0;
      height: 205px;
      width: 230px;
      display: block;
    }

    @media screen and (min-width: ${UserInterface.breakpoints.smallMobile}) {
      height: 231px;
      width: 256px;
      display: block;
      bottom: -37px;
      left: 8%;
    }

    @media screen and (min-width: 1050px) {
      height: 253px;
      width: 305px;
    }

    @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
      height: 300px;
      width: 340px;
      bottom: -50px;
      left: 5%;
    }

    @media screen and (min-width: ${UserInterface.breakpoints.table}) {
      height: 340px;
      width: 420px;
      left: 7%;
    }
  }
`

const CtaButton = styled(props => <div {...props} />)`
  margin-bottom: 3rem;

  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    margin-bottom: 0;
  }
`

const blobData = [
  { id: 'blob1', picture: 'wheelPicture1-2x.webp' },
  { id: 'blob2', picture: 'wheelPicture2-2x.webp' },
  { id: 'blob3', picture: 'wheelPicture3-2x.webp' },
]

/**
 * Component
 */
const Wheel: FC<IWheel> = ({ mainTitle, ctaText, ctaLink, children }) => {
  const [highlightedIndex, setHighlightedIndex] = React.useState<number>(0)

  const nextHighlitedItem = () => {
    setHighlightedIndex(prevIndex => (prevIndex + 1) % 6)
  }

  const prevHighlitedItem = () => {
    setHighlightedIndex(prevIndex => (prevIndex === 0 ? 6 - 1 : prevIndex - 1))
  }

  return (
    <MainContainer className="w-100 d-flex align-items-center">
      {blobData.map(blob => (
        <BlobContainer
          key={blob.id}
          className={blob.id}
          src={`${process.env.GATSBY_CDN_HOST}/components/wheel/${blob.picture}`}
        ></BlobContainer>
      ))}

      <WheelContainer $isHighlighted={highlightedIndex !== 0}>
        <Grid>
          {Children.map(children, (child, index: number) => {
            const wheelIndex = index + 1

            return React.cloneElement(child as ReactElement, {
              wheelIndex,
              setHighlightedIndex,
              highlightedIndex,
            })
          })}
        </Grid>
        {
          <WheelMainText $isShowing={highlightedIndex === 0}>
            {mainTitle}
          </WheelMainText>
        }
      </WheelContainer>

      <ButtonsContainer>
        <ButtonContainer onClick={prevHighlitedItem}>
          <Svg src="common/icon/unicolor/arrow-left-light" color="middleGrey" />
        </ButtonContainer>
        <ButtonContainer onClick={nextHighlitedItem}>
          <Svg
            src="common/icon/unicolor/arrow-right-light"
            color="middleGrey"
          />
        </ButtonContainer>
      </ButtonsContainer>

      <CtaButton>
        <ButtonLink href={ctaLink} size="medium" theme="blue">
          {ctaText}
        </ButtonLink>
      </CtaButton>
    </MainContainer>
  )
}

export default Wheel
