import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import Slider from 'react-slick'
import { UserInterface, Svg, Flex } from '../../'
import SliderTestimonyNavigation from './SliderTestimonyNavigation'
import 'slick-carousel/slick/slick.css'
import classNames from 'classnames/bind'

/**
 * Style
 */
const widthNav = 150
const SliderTestimonyWrapper = styled.div`
  max-width: 90%;
  width: 63.529rem;
  margin: 0 auto;
  text-align: center;

  /* Mobile mode */
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    max-width: none;
    width: 100vw;
    margin-left: -2.5rem;
  }
`
const SliderTestimonyFlex = styled(props => <Flex {...props} />)`
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    flex-direction: column-reverse;
  }
`
const SliderTestimonyStyled = styled.div`
  width: calc(100% - ${widthNav * 2}px);
  margin-left: ${widthNav}px;
  position: relative;

  /* Desktop mode */
  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    & .slick-list {
      background-color: ${UserInterface.colors.white};
      border-radius: 1.176rem;
      box-shadow: ${UserInterface.shadows.slider};
    }
  }
  /* Mobile mode */
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    width: 100%;
    margin-left: 0;
  }
`
const SliderTestimonyQuote = styled(props => <Svg {...props} />)`
  position: absolute;
  z-index: 1;
  top: -2.714rem;
  left: -3.353rem;

  /* Mobile mode */
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    left: auto;
    top: -1rem;
    right: 40px;
    svg {
      width: 5.5rem;
      height: 5.5rem;
    }
  }
`
const SliderTestimonyNavigationStyled = styled.div`
  .slick-list {
    width: ${widthNav}px;
  }

  /* Mobile mode */
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    width: 100vw;
    max-width: 350px;
    .slick-list {
      width: 100%;
    }
  }
`
const SliderTestimonyArrowsStyled = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: center;

  .slick-prev {
    transform: rotate(-90deg);
  }
  .slick-next {
    transform: rotate(90deg);
  }
  /* Mobile mode */
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    display: none;
  }
`
const SliderTestimonyArrows = ({ onClick, color, className }) => (
  <SliderTestimonyArrowsStyled onClick={onClick}>
    <Svg
      width="1.941rem"
      height="1.941rem"
      src="common/icon/unicolor/arrow"
      color={color}
      className={classNames(className)}
    />
  </SliderTestimonyArrowsStyled>
)

/**
 * Component
 */
const SliderTestimony = ({
  children,
  color,
  maxLenghtTestimony,
  className,
}) => {
  const [nav, setNav] = useState(null)
  const [slider, setSlider] = useState(null)

  const navRef = useRef(null)
  const sliderRef = useRef(null)

  // Slick settings
  const settingsSlider = {
    infinite: true,
    arrows: false,
    dots: false,
    fade: true,
    responsive: [
      {
        breakpoint: UserInterface.breakpoints.mobile,
        settings: {
          focusOnSelect: true,
        },
      },
    ],
  }

  const settingsNavigation = {
    infinite: true,
    slidesToShow: 3,
    vertical: true,
    focusOnSelect: true,
    centerPadding: '0',
    centerMode: true,
    nextArrow: <SliderTestimonyArrows color={color} />,
    prevArrow: <SliderTestimonyArrows color={color} />,
    responsive: [
      {
        breakpoint: UserInterface.breakpoints.mobile,
        settings: {
          vertical: false,
        },
      },
    ],
  }

  useEffect(() => {
    setNav(navRef.current)
    setSlider(sliderRef.current)
  }, [])

  return (
    <SliderTestimonyWrapper className={classNames(className)}>
      <SliderTestimonyFlex custom={false} alignItems="center">
        <SliderTestimonyStyled>
          <SliderTestimonyQuote
            width="6rem"
            color="blue"
            src="components/slider-testimonies/quote"
          />
          <Slider ref={sliderRef} asNavFor={nav} {...settingsSlider}>
            {React.Children.map(children, child =>
              React.cloneElement(child, { maxLenghtTestimony })
            )}
          </Slider>
        </SliderTestimonyStyled>

        <SliderTestimonyNavigationStyled>
          <Slider ref={navRef} asNavFor={slider} {...settingsNavigation}>
            {React.Children.map(children, ({ props: { user } }, i) => (
              <SliderTestimonyNavigation
                color={color}
                key={i}
                maxLenghtTestimony={maxLenghtTestimony}
                {...user}
              />
            ))}
          </Slider>
        </SliderTestimonyNavigationStyled>
      </SliderTestimonyFlex>
    </SliderTestimonyWrapper>
  )
}

SliderTestimony.displayName = 'SliderTestimony' // https://github.com/facebook/react/issues/4915#issuecomment-335803765

export default SliderTestimony
