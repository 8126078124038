import React, { useState } from 'react'
import styled from 'styled-components'
import { UserInterface, Svg, Text, Flex, Space } from '.'
import AnimateHeight from 'react-animate-height'

/**
 * Style
 */
const ExpansionPanelStyled = styled.div`
  box-shadow: ${UserInterface.shadows.sliderLight};
  border-radius: 10px;
  padding: 1rem;
  cursor: pointer;
  align-self: end;
`
const ExpansionPanelArrow = styled(props => (
  <Svg src="common/icon/unicolor/arrow" {...props} />
))`
  transition: 0.3s;

  &.expansion--open {
    transform: rotate(90deg);
  }
`
const ExpansionPanelTitle = styled(props => <Text {...props} />)`
  flex: 1;
  font-weight: ${UserInterface.fonts.weight.bold};
`

/**
 * Component
 */
const ExpansionPanel = ({ title, description }) => {
  const [height, setHeight] = useState(0)

  const toggle = () => {
    setHeight(prevHeight => (prevHeight === 0 ? 'auto' : 0))
  }

  return (
    <ExpansionPanelStyled onClick={toggle}>
      <Flex alignItems="center" custom={false}>
        <ExpansionPanelTitle color="middleGrey">{title}</ExpansionPanelTitle>
        <ExpansionPanelArrow
          width="10px"
          className={height === 0 ? 'expansion--close' : 'expansion--open'}
        />
      </Flex>
      <AnimateHeight height={height} duration={300}>
        <Space size="small" />
        <Text>{description}</Text>
      </AnimateHeight>
    </ExpansionPanelStyled>
  )
}
export default ExpansionPanel
