import {
  AdaptiveImage,
  Box,
  ButtonLink,
  Flex,
  Highlight,
  Link,
  MiddleTitle,
  Section,
  Space,
  Svg,
  UserInterface,
} from '.'

import AnimateHeight from 'react-animate-height'
import React, { useState } from 'react'
import { formatMessage } from '../utils/translations'
import styled from 'styled-components'

/**
 * Style
 */
const FooterSocialWrapper = styled.div`
  /* Mobile mode */
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    display: flex;
    justify-content: space-between;
  }
`
const FooterSocial = styled(props => <Svg {...props} />)`
  margin: 2px 2.7px;
  display: inline-block;

  /* Mobile mode */
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    svg {
      width: 2rem;
      height: 2rem;
    }
    margin: 0;
  }
`

const KillerArgument = styled(({ ...rest }) => <div {...rest} />)`
  font-family: ${UserInterface.fonts.family.roboto};
  font-weight: 200;
  color: ${UserInterface.colors.middleGrey};
  font-size: 14px;
  text-align: center;
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    text-align: center;
  }
`

const FooterLogoAssoconnect = styled(props => <Svg {...props} />)`
  margin: 2px;
  margin-bottom: 0.5rem;
  /* Mobile mode */
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    svg {
      width: 8.5rem;
    }
    margin-left: auto;
    margin-right: auto;
  }
`
const FooterInner = styled(props => <Flex {...props} />)`
  max-width: ${UserInterface.widthBoxed}px;
  display: inline-block;
  width: 100%;
  text-align: left;

  /* Mobile mode */
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    max-width: 17.647rem;
    width: 90%;
    text-align: center;
  }
`
const FooterCopyright = styled.p`
  text-align: center;
  margin: 1rem;

  /* Mobile mode */
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    margin: 0;
  }
`
const FooterStyled = styled.footer`
  font-family: ${UserInterface.fonts.family.roboto};
  font-weight: ${UserInterface.fonts.weight.light};
  font-size: 0.824rem;
  color: ${UserInterface.colors.white};
  padding: 1rem 30px;
  background-color: ${UserInterface.colors.darkBlue};
  position: relative;
  z-index: 10;
  text-align: center;
  background: linear-gradient(to bottom, #00174d, #011e62 11.765rem);

  /* Mobile mode */
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    font-size: 0.9rem;
  }
`
const FooterTitle = styled.li`
  text-transform: uppercase;
  margin: 1rem 0;
  text-align: left;

  /* Mobile mode */
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
`
const FooterLink = styled(props => <Link {...props} />)`
  opacity: 0.5;
  transition: 0.3s;

  &:hover {
    opacity: 1;
  }
`
const FooterArrow = styled(props => (
  <Svg src="common/icon/unicolor/arrow" {...props} />
))`
  footer & {
    transition: 0.3s;

    &.footer__section--open {
      transform: rotate(90deg);
    }
  }
`
const FooterList = styled.li`
  text-align: left;
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    line-height: 48px;
  }
`
const FooterLanguageSwitch = styled(props => <select {...props} />)`
  border-radius: 4px;
  padding: 5px 20px 5px 5px;
  font-size: 14px;
  border: 1px solid #fff;
  background: transparent;
  color: #fff;
  margin: 14px 0 2px;
`
const FooterLanguageSwitchOption = styled(props => <option {...props} />)`
  color: #626262;
  background: #fff;
  transition: 0.3s ease;
  min-height: 29px;
`

/**
 * Component used
 */
const AnimationFooter = ({ title, items }) => {
  const [height, setHeight] = useState(0)

  const toggle = () => {
    setHeight(prevHeight => (prevHeight === 0 ? 'auto' : 0))
  }

  return (
    <ul>
      <FooterTitle onClick={toggle}>
        {title}
        <FooterArrow
          width="10px"
          color="white"
          className={
            height === 0 ? 'footer__section--close' : 'footer__section--open'
          }
        />
      </FooterTitle>
      <AnimateHeight height={height}>
        {items.map((field, i) => (
          <FooterList key={i}>
            <FooterLink
              href={field.href}
              externalLink={!!field.externalLink}
              isStyled={false}
            >
              {field.text}
            </FooterLink>
          </FooterList>
        ))}
      </AnimateHeight>
    </ul>
  )
}

/**
 * Component
 */
const Footer = ({
  columns,
  copyrights,
  cta,
  preFooter,
  socials,
  translations,
  path,
  platform,
  footerBackground,
}) => (
  <>
    {preFooter && (
      <Section
        background={{
          color: footerBackground,
        }}
      >
        <Section>
          <Box>
            {platform === 'ASSOCONNECT' ? (
              <MiddleTitle>
                Faites entrer votre association <br className="hidden-mobile" />
                dans une <Highlight>nouvelle dimension&nbsp;</Highlight>
              </MiddleTitle>
            ) : (
              <MiddleTitle>
                Take your nonprofit management to the{' '}
                <Highlight>next level</Highlight>
              </MiddleTitle>
            )}
            <Space size="big" className="hidden-mobile" />
            <Space size="medium" />
          </Box>
          <Box width={2 / 12} />

          {platform === 'ASSOCONNECT' ? (
            <Box width={8 / 12}>
              <AdaptiveImage
                src="pages/index/onboarding-steps-fr"
                alt="assoconnect demo logiciel"
                className="hidden-mobile"
              />
              <AdaptiveImage
                src="pages/index/onboarding-steps-fr"
                alt="assoconnect demo logiciel"
                className="hidden-desktop"
              />
            </Box>
          ) : (
            <Box width={8 / 12}>
              <AdaptiveImage
                src="us/pages/bigger-frise-us"
                alt="club management software demo steps"
                className="hidden-mobile"
              />
              <AdaptiveImage
                src="us/pages/us_onboarding_mobile"
                alt="nonprofit management software demo steps"
                className="hidden-desktop"
              />
            </Box>
          )}
        </Section>

        <Box width={1 / 2} align="right">
          <ButtonLink align="center" href={cta.trial.link}>
            {cta.trial.text}
          </ButtonLink>
          <Space size="small" className="hidden-desktop" />
        </Box>
        <Box width={1 / 2}>
          <ButtonLink align="center" href={cta.demo.link} theme="white">
            {cta.demo.text}
          </ButtonLink>
        </Box>
        <Box width={1}>
          <Space size="medium" />
        </Box>
        {platform === 'ASSOCONNECT' ? (
          <Box width={1}>
            <KillerArgument>
              Essai gratuit de 14 jours - Sans engagement
            </KillerArgument>
          </Box>
        ) : (
          <Box width={1}></Box>
        )}
      </Section>
    )}
    <FooterStyled>
      <FooterInner>
        <Space size="medium" className="hidden-desktop" />
        <FooterLogoAssoconnect
          color="white"
          width="7.059rem"
          height="auto"
          src={`common/logo/${formatMessage('site_name', translations)}`}
        />
        <Space size="medium" className="hidden-desktop" />
        <FooterSocialWrapper>
          {socials.map((social, i) => (
            <FooterLink
              aria-label={social.name}
              href={social.href}
              key={i}
              isStyled={false}
            >
              <FooterSocial width="1.176rem" color="white" src={social.icon} />
            </FooterLink>
          ))}
        </FooterSocialWrapper>

        <FooterLanguageSwitch
          value={formatMessage('site_url', translations)}
          onChange={event => {
            if (window !== undefined) {
              window.location.href = event.target.value
            }
          }}
        >
          <FooterLanguageSwitchOption value="https://www.springly.org/en-us/">
            English (USA)
          </FooterLanguageSwitchOption>
          <FooterLanguageSwitchOption value="https://www.assoconnect.com/">
            France
          </FooterLanguageSwitchOption>
        </FooterLanguageSwitch>

        <Flex justifyContent="space-between" className="hidden-mobile">
          {columns.map((footerColumn, i) => (
            <ul key={i}>
              <FooterTitle>{footerColumn.title}</FooterTitle>
              {footerColumn.items.map((field, j) => (
                <li key={`${i}-${j}`}>
                  <FooterLink
                    href={field.href}
                    nofollow={field.nofollow}
                    isStyled={false}
                  >
                    {field.text}
                  </FooterLink>
                </li>
              ))}
            </ul>
          ))}
        </Flex>

        <div className="hidden-desktop">
          <Space size="medium" />
          {columns.map((footerColumn, i) => (
            <AnimationFooter {...footerColumn} key={i} />
          ))}
          <Space size="medium" />
        </div>

        <FooterCopyright>
          {formatMessage('footer_copyright', translations)}
          {copyrights.map((copyright, i) => (
            <span key={i}>
              <span>{` • `}</span>
              <FooterLink href={copyright.href} isStyled={false}>
                {copyright.text}
              </FooterLink>
            </span>
          ))}
          <span>
            <span>{` • `}</span>
            <FooterLink
              href={path}
              onClick={event => {
                event.preventDefault()
                window?.openAxeptioCookies && window?.openAxeptioCookies()
              }}
              isStyled={false}
            >
              {formatMessage('footer_cookies', translations)}
            </FooterLink>
          </span>
        </FooterCopyright>
      </FooterInner>
    </FooterStyled>
  </>
)

export default Footer
