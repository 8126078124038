import React, { FC } from 'react'
import { UserInterface } from '..'
import styled from 'styled-components'
import classNames from 'classnames'
import { IButton } from './type'

/**
 * Style
 */
const ButtonWrapper = styled(props => <div {...props} />)`
  text-align: ${({ align }) => align};
  margin: ${({ margin }) => margin};

  /* Mobile mode */
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    text-align: center;
    margin: 0.5rem 0;
  }
`
const ButtonStyled = styled(props => <button {...props} />)`
  border-radius: 2.941rem;
  outline-style: none;
  cursor: pointer;
  font-family: ${UserInterface.fonts.family.roboto};
  font-weight: ${UserInterface.fonts.weight.bold};
  transition: background-color 0.3s ease, transform 0.3s ease,
    border-color 0.3s ease !important;
  &:first-letter {
    text-transform: uppercase;
  }

  /* Sizes */
  &.button--size-big {
    font-size: 1rem;
    padding: 0.647rem 1.765rem;
  }
  &.button--size-medium {
    font-size: 0.9rem;
    padding: 0.5rem 1.176rem;
  }
  &.button--size-small {
    font-size: 0.824rem;
    padding: 0.471rem 1.176rem;
  }
  /* Themes */
  &.button--theme-yellow {
    background-color: ${UserInterface.colors.yellow};
    color: ${UserInterface.colors.white};
    border: 0.5px solid ${UserInterface.colors.yellow};
    &:hover {
      background-color: ${UserInterface.colors.darkYellow};
      border: 0.5px solid ${UserInterface.colors.darkYellow};
    }
  }
  &.button--theme-orange {
    background-color: ${UserInterface.colors.orange};
    color: ${UserInterface.colors.white};
    border: 0.5px solid ${UserInterface.colors.orange};
    &:hover {
      background-color: ${UserInterface.colors.darkOrange};
      border: 0.5px solid ${UserInterface.colors.darkOrange};
    }
  }
  &.button--theme-white {
    background-color: ${UserInterface.colors.blue};
    color: ${UserInterface.colors.white};
    border: 0.5px solid ${UserInterface.colors.white};
   
    }
  }
  &.button--theme-lightGrey {
    background-color: ${UserInterface.colors.lightGrey};
    color: ${UserInterface.colors.middleGrey};
    border: 0.5px solid ${UserInterface.colors.lightGrey};
    &:hover {
      background-color: ${UserInterface.colors.lightGrey};
      color: ${UserInterface.colors.middleGrey};
    }
  }
  &.button--theme-blue {
    background-color: ${UserInterface.colors.blue};
    color: ${UserInterface.colors.white};
    border: 0.5px solid ${UserInterface.colors.blue};
    &:hover {
      background-color: ${UserInterface.colors.mediumBlue};
      border: 0.5px solid ${UserInterface.colors.mediumBlue};
    }
  }
  &.button--theme-line-white {
    font-weight: ${UserInterface.fonts.weight.normal};
    background-color: transparent;
    color: ${UserInterface.colors.white};
    border: 1px solid ${UserInterface.colors.white};
  }
  &.button--theme-link-white {
    font-weight: ${UserInterface.fonts.weight.bold};
    background-color: transparent;
    color: ${UserInterface.colors.white};
    border: 0;
  }
  &.button--theme-link-blue {
    font-weight: 700;
    background-color: ${UserInterface.colors.blue};
    color: ${UserInterface.colors.white};
    border: 0;
    border: 1px solid ${UserInterface.colors.blue};
    padding: 0.5rem 0.9rem !important;

    @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
      padding: 0.5rem 0.9rem !important;
    }

    @media screen and (min-width: ${UserInterface.breakpoints.largeTable}) {
      padding: 0.7rem 1.1rem !important;
    }
  }
  &.button--theme-link-yellow {
    font-weight: ${UserInterface.fonts.weight.bold} !important;
    background-color: transparent;
    color: ${UserInterface.colors.middleGrey};
    border: 0;
    border: 2px solid ${UserInterface.colors.yellow};
    padding: 0.5rem 0.9rem !important;

    @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
      padding: 0.5rem 0.9rem !important;
    }

    @media screen and (min-width: ${UserInterface.breakpoints.largeTable}) {
      padding: 0.7rem 1.1rem !important;
    }
  }
  &.button--theme-line-middleGrey {
    font-weight: ${UserInterface.fonts.weight.bold};
    background-color: transparent;
    color: ${UserInterface.colors.middleGrey};
    border: 2px solid ${UserInterface.colors.yellow};
   
    }
  }
  &.button--disabled {
    cursor: default;
  }
  &.button--theme-header-blue {
      font-weight: 700;
    background-color: ${UserInterface.colors.blue};
    color: ${UserInterface.colors.white};
    border: 1px solid ${UserInterface.colors.white};
  }
`

/**
 * Component
 */
const Button: FC<IButton> = ({
  children,
  animationOff,
  className,
  isDisabled = false,
  size,
  theme,
  type,
  align = 'left',
  margin,
}) => (
  <ButtonWrapper
    align={align}
    className={classNames(className)}
    margin={margin}
  >
    <ButtonStyled
      type={type}
      className={classNames(
        'button',
        'button--type-color',
        `button--size-${size || 'big'}`,
        `button--theme-${theme || 'yellow'}`,
        { 'button--disabled': isDisabled },
        { 'animation--start-hover animation--effect-bouncing': !animationOff }
      )}
    >
      {children}
    </ButtonStyled>
  </ButtonWrapper>
)
Button.displayName = 'Button' // https://github.com/facebook/react/issues/4915#issuecomment-335803765

export default Button
