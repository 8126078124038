import React, { useState } from 'react'
import InputBasic from './InputBasic'
import { Button, UserInterface } from '../'
import styled from 'styled-components'
import colors from '../../styles/colors'
import { formatMessage } from '../../utils/translations'

/* Blog  */
const InputButtonWrapperBlog = styled.div`
  max-width: 100%;
  position: relative;
  width: auto;
  flex-direction: column;
  > div {
    text-align: center;
  }
`
const InputButtonStyledBlog = styled(InputBasic)`
  padding-right: 0;
  width: 100%;
  border: 0;
  border-radius: 0;
  border-bottom: solid 1px #fff;
  background: 0;
  .input {
    width: 100%;
    color: white;
  }
  .outline & {
    border: 1px solid ${colors.yellow};
  }
  .input::placeholder {
    color: white !important;
  }
  padding: 0.588rem;
`
const InputButtonSendBlog = styled(Button)`
  vertical-align: middle;
  margin: 1.765rem auto auto auto;
  transition: background-color 0.3s;
  outline: none;
  text-transform: uppercase;
  border-radius: 21.5px;
  border: none;
  color: #316bf2;
  font-size: 16px;
  font-weight: 500;
  line-height: 21px;
  .button {
    height: 43px;
    width: 154px;
    background-color: #ffffff;
    padding: 0.647rem 1.1rem;
    transition: 0.3s;
    color: #316bf2;
    border: 1px solid ${colors.white};

    .outline & {
      border: 1px solid ${colors.white};
    }
    &:hover {
      background-color: transparent;
      border-color: ${colors.white};
      color: ${colors.white};
    }
  }
`
const ErrorMessageBlog = styled.div`
  font-family: ${UserInterface.fonts.family.roboto};
  font-weight: ${UserInterface.fonts.weight.bold};
  color: #f4674a;
  margin-bottom: 5px;
`

/**
 * Style
 */
const FormStyled = styled.form`
  text-align: left;

  /* Mobile mode */
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    text-align: center;
  }
`
const InputButtonContainer = styled.div`
  display: inline-block;
  width: 100%;
`
const InputButtonWrapper = styled.div`
  margin-top: 1rem;
  display: inline-flex;
  align-content: stretch;
  max-width: 100%;
  position: relative;
  background-color: ${colors.clearBlue};
  padding: 0.4rem;
  border-radius: 2.941rem;

  /* Mobile mode */
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
  }
`
const InputButtonStyled = styled(InputBasic)`
  padding-right: 0;
  flex-grow: 3;
  height: 41px;
  background-color: ${colors.clearBlue} !important;
  padding-top: 0.55rem;
  padding-left: 0.5rem;
  width: 14rem;

  .input::placeholder {
    font-size: 0.8rem;
    font-weight: 400;
  }
  .input {
    width: 100%;
    font-size: 0.9rem;
  }
  .outline & {
    border: 1px solid ${colors.yellow};
  }

  /* Mobile mode */
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
  }
  /* Desktop mode */
  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    border-radius: 2.941rem 0 0 2.941rem;
  }
`
const InputButtonButton = styled(Button)`
  vertical-align: middle;

  /* Mobile mode */
  @media screen and (max-width: ${UserInterface.breakpoints.largeTable}) {
    font-size: 0.8rem;
  }

  .button {
    font-size: 0.8rem;
    height: 41px;
    padding: 0.647rem 1.1rem;
    line-height: 14px;
    transition: 0.3s;
    .outline & {
      border: 1px solid ${colors.yellow};
    }
    .shadow & {
      box-shadow: 0 7px 20px 0 rgba(49, 107, 242, 0.3);
    }
    &:hover {
      background-color: #f9ac29;
      border-color: #f9ac29;
    }
    @media screen and (max-width: 600px) {
      padding: 0.4rem 1.1rem;
    }

    /* Desktop mode */
    @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
      border-radius: 2.941rem;
      margin: 0;
    }
  }
`
const ErrorMessage = styled.div`
  font-family: ${UserInterface.fonts.family.roboto};
  font-weight: ${UserInterface.fonts.weight.bold};
  color: #f4674a;
  margin-bottom: 5px;
`

const getHubspotBody = (data, newsletter) => {
  const { email, referrer, page, pageUri, pageName } = data
  const hubspotData = {
    fields: [
      {
        name: 'email',
        value: email,
      },
      {
        name: 'referrer',
        value: referrer,
      },
    ],
    context: {
      pageUri,
      pageName,
    },
    legalConsentOptions: {
      consent: {
        consentToProcess: true,
        text: formatMessage('inputbutton_consent'),
      },
    },
  }

  if (!newsletter) {
    hubspotData.fields.push({
      name: 'page',
      value: page,
    })
  }

  return JSON.stringify(hubspotData)
}

/**
 * Component
 */
const InputButton = ({
  className,
  translations,
  href = 'https://app.assoconnect.com/sign-up',
  hubspotId,
  hubspotCode = 3038993,
  newsletter,
  encodeEmail = true,
}) => {
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(null)

  const goToSuccessPage = () => {
    const encodedEmail = encodeEmail ? encodeURIComponent(email) : email
    setTimeout(() => {
      window.dataLayer = window?.dataLayer || []
      window.dataLayer.push({
        'email-passed': email,
      })
      if (window?.location.search.length || href.indexOf('?') !== -1) {
        if (window?.location.search.length) {
          window.location.href = `${href}${window?.location.search}&email=${encodedEmail}`
        } else {
          window.location.href = `${href}&email=${encodedEmail}`
        }
      } else {
        window.location.href = `${href}?email=${encodedEmail}`
      }
    }, 400)
  }

  const fetchHubspot = newsletter => {
    if (hubspotId) {
      fetch(
        `https://api.hsforms.com/submissions/v3/integration/submit/${hubspotCode}/${hubspotId}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
          mode: 'cors',
          method: 'POST',
          body: getHubspotBody(
            {
              email,
              referrer: document.referrer,
              page: document.title,
              pageUri: window?.location.href,
              pageName: document.title,
            },
            newsletter
          ),
        }
      )
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          goToSuccessPage()
        })
    } else {
      goToSuccessPage()
    }
  }

  const handleSubmit = (event, newsletter) => {
    event.preventDefault()
    setEmailError('')
    // Errors
    if (!email) {
      setEmailError(
        formatMessage('inputbutton_validation_required', translations)
      )
      return
    }
    if (
      email.match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ) === null
    ) {
      setEmailError(formatMessage('inputbutton_validation_email', translations))
      return
    }
    // Submit
    fetchHubspot(newsletter)
  }

  const handleChange = event => {
    setEmailError('')
    setEmail(event.target.value)
  }

  return (
    <FormStyled
      className={className}
      onSubmit={event => handleSubmit(event, newsletter)}
    >
      {newsletter ? (
        <InputButtonContainer>
          {emailError && <ErrorMessageBlog>{emailError}</ErrorMessageBlog>}
          <InputButtonWrapperBlog className="button animation--start-hover">
            <InputButtonStyledBlog
              name="email"
              type="text"
              placeholder={formatMessage(
                'inputbutton_placeholder_blog',
                translations
              )}
              onChange={handleChange}
              value={email}
            />
            <InputButtonSendBlog
              type="submit"
              theme="yellow"
              size="big"
              animationOff
            >
              {formatMessage('inputbuttonNewsletter_text', translations)}
            </InputButtonSendBlog>
          </InputButtonWrapperBlog>
        </InputButtonContainer>
      ) : (
        <InputButtonContainer>
          {emailError && <ErrorMessage>{emailError}</ErrorMessage>}
          <InputButtonWrapper className="button animation--start-hover">
            <InputButtonStyled
              name="email"
              type="text"
              placeholder={formatMessage(
                'inputbutton_placeholder',
                translations
              )}
              onChange={handleChange}
              value={email}
            />
            <InputButtonButton
              type="submit"
              theme="blue"
              size="big"
              animationOff
            >
              {formatMessage('inputbutton_text', translations)}
            </InputButtonButton>
          </InputButtonWrapper>
        </InputButtonContainer>
      )}
    </FormStyled>
  )
}

export default InputButton
