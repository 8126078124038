import translations from '../translations'

import {
  serenityNavigationFR,
  paymentNavigationFR,
  babyAssoNavigationFR,
  defaultNavigationFR,
  networksNavigationFR,
  accountingNavigationFR,
  blogNavigationFR,
  proAccountNavigationFR,
} from 'src/contents/fr-fr/navigation'

import {
  defaultNavigationUS,
  blogNavigationUS,
} from 'src/contents/en-us/navigation'

export const getBrand = () => {
  return process.env.GATSBY_CONTEXT_BRAND === 'ASSOCONNECT'
    ? 'ASSOCONNECT'
    : 'SPRINGLY'
}

export const getLang = () => {
  return process.env.GATSBY_CONTEXT_BRAND === 'ASSOCONNECT' ? 'fr' : 'en'
}

export const getTranslations = () => {
  return process.env.GATSBY_CONTEXT_BRAND === 'ASSOCONNECT'
    ? translations.fr_FR
    : translations.en_US
}

export const getLocale = () => {
  return process.env.GATSBY_CONTEXT_BRAND === 'ASSOCONNECT' ? 'fr_FR' : 'en_US'
}

export const getCanonical = (location, canonical) => {
  return `${process.env.GATSBY_SITE_URL}${canonical || location.pathname}`
}

export const getHeaderItems = (type, partnershipLink) => {
  const brand = getBrand()

  const getNavigationButtons = plan => {
    switch (plan) {
      case 'SERENITY':
        return {
          ASSOCONNECT: serenityNavigationFR,
        }
      case 'PAYMENT':
        return {
          ASSOCONNECT: paymentNavigationFR,
        }
      case 'NETWORKS':
        return {
          ASSOCONNECT: networksNavigationFR,
        }
      case 'ACCOUNTING':
        return {
          ASSOCONNECT: accountingNavigationFR,
        }
      case 'BABYASSO':
        return {
          ASSOCONNECT: babyAssoNavigationFR,
        }
      case 'BLOG':
        return {
          ASSOCONNECT: blogNavigationFR,
          SPRINGLY: blogNavigationUS,
        }
      case 'PROACCOUNT':
        return {
          ASSOCONNECT: proAccountNavigationFR,
          SPRINGLY: defaultNavigationUS,
        }
      default:
        return {
          ASSOCONNECT: defaultNavigationFR,
          SPRINGLY: defaultNavigationUS,
        }
    }
  }

  const navigationButtons = getNavigationButtons(type)[brand]

  if (partnershipLink) {
    navigationButtons.navigationButtons[1].href = partnershipLink
  }

  return navigationButtons
}
