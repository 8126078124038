import React from 'react'
import styled from 'styled-components'
import { ButtonLink, UserInterface, Svg } from '..'
import { formatMessage } from '../../utils/translations'
import { getTranslations } from '../../utils/context'
/**
 * Style
 */
const NavigationButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  /* Mobile mode */
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column-reverse;
    background-color: ${UserInterface.colors.white};
    margin-top: auto;
    height: 220px;
  }
  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    & > div:first-child > a {
      margin-left: 0;
    }
    & > div:last-child > a {
      margin-right: 0;
    }
  }
`
const NavigationButtonsStyled = styled(props => <ButtonLink {...props} />)`
  display: inline-block;
  margin: 0 10px;

  & .button--size-small {
    font-weight: normal;
    padding: 0.7rem 1.1rem;
  }
  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    width: max-content;
  }

  /* Mobile mode */
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    margin: 15px;
    .button {
      font-size: 1rem;
      padding: 0.9rem 1.2rem !important;

      &.button--theme-link-yellow {
        background-color: ${UserInterface.colors.white} !important;
      }

      &.button--theme-line-white {
        color: ${UserInterface.colors.white} !important;
        border-color: ${UserInterface.colors.white} !important;
      }
      &.button--theme-link-white {
        color: ${UserInterface.colors.white} !important;
      }
    }
  }
`
const PhoneNumberStyled = styled.span`
  text-align: center;
  display: inline-block;
  color: ${UserInterface.colors.darkGrey};
  font-family: ${UserInterface.fonts.family.roboto};
  font-size: 0.8rem;
  margin-top: 4px;
`
const SvgStyled = styled(props => <Svg {...props} />)`
  display: inline-block;
  vertical-align: middle;
  margin-right: 7px;
`
const WrapperPhone = styled.div`
  margin-bottom: 12px;
  margin-right: 10px;
  text-align: right;
`
const WisepopContainer = styled.div`
  margin-top: 0.8rem;
  margin-bottom: 1.2rem;

  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    display: none;
  }
`

const WisepopBell = styled.svg`
  margin: O.4rem;

  path {
    stroke: black;
  }
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    path {
      stroke: white;
    }
  }
`

/**
 * Component
 */
const NavigationButtons = ({ navigationIsFixed, navigationButtons = [] }) => {
  const phoneNumber = formatMessage('phone_number', getTranslations())

  return (
    <>
      {!navigationIsFixed && phoneNumber && (
        <WrapperPhone className="phoneNumber">
          <SvgStyled width="12px" src="pages/index/phone-alt-solid" />
          <PhoneNumberStyled>{phoneNumber}</PhoneNumberStyled>
        </WrapperPhone>
      )}

      <NavigationButtonsWrapper width="auto">
        <WisepopContainer className="wisp">
          <WisepopBell
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1"
            width="22"
            height="22"
            viewBox="0 0 24 24"
            stroke="black"
            xmlns="<http://www.w3.org/2000/svg>"
          >
            <path d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"></path>
          </WisepopBell>
        </WisepopContainer>
        {navigationButtons.map(navButton => (
          <div className="text-center" key={navButton.text}>
            <NavigationButtonsStyled
              id={navButton.id}
              href={navButton.href}
              size="small"
              className="navigation__buttons"
              target={navButton.target ?? '_self'}
              theme={navButton.theme || undefined}
            >
              <span>{navButton.text}</span>
            </NavigationButtonsStyled>
          </div>
        ))}
      </NavigationButtonsWrapper>
    </>
  )
}
export default NavigationButtons
