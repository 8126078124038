import React, { FC } from 'react'
import styled from 'styled-components'
import { Flex, UserInterface } from '../..'
import { EHorizontalPosition, ITestimonialWithPicture } from '../type'
import { TextContainer } from '../Testimonial'
import TestimonialTextContent from '../components/TextContent'

/**
 * Style
 */
const TestimonialWithPictureContainer = styled(props => <Flex {...props} />)`
  align-items: center;
  min-height: 330px;
  flex-direction: ${props =>
    props.$imagePosition === EHorizontalPosition.RIGHT
      ? 'row-reverse'
      : 'initial'};

  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    flex-direction: column;
    min-height: 700px;
  }
`

const ImageStyled = styled.img`
  max-width: 280px;
  object-fit: cover;
  border-radius: 20px;

  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    max-width: 100%;
    padding: 2rem 0;
  }
`

/**
 * Component
 */
const TestimonialWithPicture: FC<ITestimonialWithPicture> = ({
  image,
  imagePosition = EHorizontalPosition.LEFT,
  alt,
  type,
  ...rest
}) => {
  return (
    <TestimonialWithPictureContainer
      $imagePosition={imagePosition.toUpperCase()}
    >
      <ImageStyled
        loading="lazy"
        src={`${process.env.GATSBY_CDN_HOST}/${image}.${type || 'png'}`}
        alt={alt}
      />

      <TextContainer $imagePosition={imagePosition.toUpperCase()}>
        <TestimonialTextContent {...rest} />
      </TextContainer>
    </TestimonialWithPictureContainer>
  )
}

export default TestimonialWithPicture
