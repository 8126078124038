import React, { Children, FC, useRef, useState } from 'react'
import styled from 'styled-components'
import { Flex, Svg, UserInterface } from '..'
import { ITestimonials } from './type'

/**
 * Style
 */
const TestimonialsContainer = styled(props => <Flex {...props} />)`
  opacity: 1;
  transition: opacity 0.5s ease-in-out;
  max-width: 1050px;
  display: block;

  &.fade-out {
    opacity: 0;
  }

  &.fade-in {
    opacity: 1;
  }

  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    flex-direction: column;
    max-width: 600px;

    img {
      padding: 0;
    }
  }
`

const TestimonialActions = styled.div`
  align-items: center;
  flex-direction: row;
  width: fit-content;
  display: flex;
  justify-content: space-between;
  margin-top: 1rem;
  padding-top: 1rem;
  max-width: 1050px;
  margin: 0 auto;

  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    width: 100%;
  }
`

const TestimonialDot = styled.div`
  width: 10px;
  min-width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${UserInterface.colors['lightGrey']};
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &.active {
    background-color: ${UserInterface.colors['blue']};
  }
`

const ButtonsContainer = styled(props => <Flex {...props} />)`
  flex-direction: row;
  justify-content: end;

  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    display: none;
  }
`

const ButtonContainer = styled.div`
  border: 1px solid ${UserInterface.colors['middleGrey']};
  border-radius: 50px;
  padding: 0.5rem;
  cursor: pointer;
  margin: 0 0.2rem;

  &:hover {
    box-shadow: 0 1px 3px 0 ${UserInterface.colors.lightGrey};
  }

  svg {
    width: 16px;
  }

  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    padding: 0.9rem;

    svg {
      width: 18px;
    }
  }
`

/**
 * Component
 */
const Testimonials: FC<ITestimonials> = ({ children }) => {
  const [currentTestimony, setCurrentTestimony] = useState(0)
  const [fadeAnimation, setFadeAnimation] = useState(false)

  const childrenNumber = Children.count(children)

  const swipeRef = useRef(null)
  let startX = 0
  let currentX = 0

  const handleTouchStart = (e: React.TouchEvent) => {
    if (childrenNumber === 1) return
    startX = e.touches[0].clientX
  }

  const handleTouchMove = (e: React.TouchEvent) => {
    currentX = e.touches[0].clientX
  }

  const handleTouchEnd = () => {
    const difference = currentX - startX
    if (difference > 50) {
      nextTestimony()
    } else if (difference < -50) {
      prevTestimony()
    }
  }

  const nextTestimony = () => {
    setFadeAnimation(true)

    setTimeout(() => {
      setFadeAnimation(false)
      setCurrentTestimony(prevIndex => (prevIndex + 1) % childrenNumber)
    }, 500)
  }

  const prevTestimony = () => {
    setFadeAnimation(true)

    setTimeout(() => {
      setFadeAnimation(false)
      setCurrentTestimony(prevIndex =>
        prevIndex === 0 ? childrenNumber - 1 : prevIndex - 1
      )
    }, 500)
  }

  return (
    <div
      ref={swipeRef}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <TestimonialsContainer
        flexDirection="row"
        alignItems="center"
        className={`${fadeAnimation ? 'fade-out' : 'fade-in'}`}
      >
        {Children.map(children, (child, index) =>
          index === currentTestimony ? child : null
        )}
      </TestimonialsContainer>
      {childrenNumber > 1 && (
        <TestimonialActions>
          {[...Array(childrenNumber)].map((_, index) => (
            <TestimonialDot
              key={index}
              className={`${index === currentTestimony ? 'active' : ''}`}
              onClick={() => {
                setFadeAnimation(true)

                setTimeout(() => {
                  setFadeAnimation(false)
                  setCurrentTestimony(index)
                }, 500)
              }}
            />
          ))}

          <ButtonsContainer>
            <ButtonContainer onClick={prevTestimony}>
              <Svg
                src="common/icon/unicolor/arrow-left-light"
                color="middleGrey"
              />
            </ButtonContainer>
            <ButtonContainer onClick={nextTestimony}>
              <Svg
                src="common/icon/unicolor/arrow-right-light"
                color="middleGrey"
              />
            </ButtonContainer>
          </ButtonsContainer>
        </TestimonialActions>
      )}
    </div>
  )
}

export default Testimonials
