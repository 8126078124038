import React from 'react'
import styled from 'styled-components'
import { Flex, UserInterface, VideoButton } from '../'
import classNames from 'classnames/bind'

/**
 * Style
 */
const getColor = color => {
  // Theme colors
  if (color in UserInterface.colors) {
    return UserInterface.colors[color]
  }
  // Custom color
  else if (String(color).search('#') !== -1) {
    return color
  }
}
export const HeaderStyled = styled(props => (
  <Flex custom={false} style={props.style} {...props} />
))`
  min-height: ${({ $isFullHeight }) =>
    $isFullHeight ? 'calc(100vh - 100px)' : 'auto'};
  padding: ${({ $isFullHeight }) => ($isFullHeight ? 'auto' : '2rem 0')};
  position: relative;
  text-align: left;
  background-color: ${({ backgroundColor }) => getColor(backgroundColor)};

  ${({ style }) => ({ ...style })}
`
export const HeaderContent = styled(props => <Flex {...props} />)`
  min-height: ${({ $isFullHeight }) =>
    $isFullHeight ? 'calc(100vh - 100px)' : 'auto'};
`
const KillerArgumentText = styled(({ ...rest }) => <div {...rest} />)`
  font-family: ${UserInterface.fonts.family.poppins};
  font-weight: 600;
  color: ${({ color }) => UserInterface.colors[color]};
  text-align: left;
  font-size: 14px;
  margin-top: 6px;
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    text-align: center;
  }
`

export const KillerArgument = ({ color = 'white', children }) => (
  <KillerArgumentText color={color}>{children}</KillerArgumentText>
)

/**
 * Component
 */
const Header = ({
  className,
  children,
  style,
  videoId,
  animationVideoButton = true,
  translations,
  backgroundColor = 'blue',
  justifyContent = 'start',
  isFullHeight = true,
}) => (
  <HeaderStyled
    style={style}
    className={classNames(className, 'header')}
    backgroundColor={backgroundColor}
    $isFullHeight={isFullHeight}
  >
    <HeaderContent
      $isFullHeight={isFullHeight}
      justifyContent={justifyContent}
      alignItems="center"
    >
      {children}
    </HeaderContent>
    {videoId && (
      <VideoButton
        animationVideoButton={animationVideoButton}
        videoId={videoId}
        translations={translations}
      />
    )}
  </HeaderStyled>
)

export default Header
