import { Colors } from './type'

// TO DO: Delete this file after all components are using Typescript
const colors: Colors = {
  primary: '#316BF2',
  blue: '#316BF2',
  mediumBlue: '#1257F6',
  darkBlue: '#011E62',
  extraDarkBlue: '#00174d',

  turquoise: '#00C1A2',
  darkTurquoise: '#00AA8E',

  yellow: '#F6C131',
  red: '#F36052',

  extraDarkGrey: '#333333',
  darkGrey: '#999999',
  middleGrey: '#626262',
  lightGrey: '#DEDEDE',
  extraLightGrey: '#F5F5F5',

  white: '#FFFFFF',

  pastelBlue: '#D6E1FC',
  clearBlue: '#E6EDFD',
  extraClearBlue: '#f9fbff',
  darkYellow: '#e1ad21',
  orange: '#ea8f25',
  lightOrange: '#efbb7e',
  darkOrange: '#c77a1f',
  specificBlue: '#F4F7FE',
}

export default colors
