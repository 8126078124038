import React, { FC } from 'react'
import styled from 'styled-components'
import { Flex, Svg, ButtonText, Text } from '../..'
import { ITestimonialTextContent } from '../type'
import { getTranslations } from '../../../utils/context'

/**
 * Style
 */

const Quote = styled(props => <Svg {...props} />)`
  position: relative;
  top: 25px;
  text-align: left !important;
`

const Statement = styled.div`
  text-indent: 2.2rem;
`

const Witness = styled.div`
  margin: 1.5rem 0;

  .witnessDetails {
    font-size: 0.9rem;
  }
`

const AuthorName = styled(props => <Text {...props} />)`
  margin-bottom: 0.4rem;
  font-weight: 700 !important;
`

/**
 * Component
 */
const TestimonialTextContent: FC<ITestimonialTextContent> = ({
  statement,
  authorName,
  authorRole,
  nonprofitName,
  nonprofitDescription,
  nonprofitNumberOfMembers,
  link,
}) => {
  const translations = getTranslations()

  return (
    <>
      <Quote
        width="2rem"
        color="blue"
        src="components/slider-testimonies/quote"
      />
      <Statement>
        <Text>{statement}</Text>
      </Statement>
      <Witness>
        <AuthorName>
          {authorName} - {authorRole}
        </AuthorName>
        <Text className="witnessDetails">
          {nonprofitName} - {nonprofitNumberOfMembers}{' '}
          {translations.testimonialMembers}
        </Text>
        <Text className="witnessDetails">{nonprofitDescription}</Text>
      </Witness>
      {link && (
        <Flex justifyContent="left">
          <ButtonText href={link} target="_self">
            {translations.testimonialRead}
          </ButtonText>
        </Flex>
      )}
    </>
  )
}

export default TestimonialTextContent
