import { Buffer } from 'buffer'
export { default as UserInterface } from '../styles/'

// Quickfix "Buffer is not defined"
// https://stackoverflow.com/questions/67804053/uncaught-referenceerror-buffer-is-not-defined-in-react
global.Buffer = global.Buffer || Buffer

/**
 * Wrapper
 */
export { default as AppWrapper } from './AppWrapper'
/**
 * Commons
 */
export { default as AdaptiveImage } from './AdaptiveImage'
export { default as Arrow } from './Arrow'
export { default as ArrowNew } from './ArrowNew'
export { default as Box } from './Box'
export { default as Card } from './Cards/Card'
export { default as SixCards } from './Cards/SixCards'
export { default as CardsMulti } from './Cards/CardsMulti'
export { default as CardsOffer } from './Cards/CardsOffer'
export { default as CardMulti } from './Cards/CardsMulti/components/cardMulti'
export { default as CountUp } from './CountUp'
export { default as Dots } from './Cards/Components/Dots'
export { default as Emoji } from './Emoji'
export { default as ExpansionPanel } from './ExpansionPanel'
export { default as Flex } from './Flex'
export { default as Highlight } from './Highlight'
export { default as Link } from './Link'
export { default as Space } from './Space'
export { default as Svg } from './Svg'
export { default as SyntaxHighlighter } from './SyntaxHighlighter'
export { default as Team } from './Team'
export { default as TextBox } from './TextBox'
export { default as Tooltip } from './Tooltip'
export { default as Video } from './Video'
export { default as VideoButton } from './Video/VideoButton'
export { default as VideoImage } from './Video/VideoImage'
export { default as VideoPlaceholder } from './Video/VideoPlaceholder'
export { default as VideoWithImage } from './Video/VideoWithImage'
export { default as HubspotContactForm } from './Hubspot/HubspotContactForm'

/**
 * Animations
 */
export { default as AnimationTyping } from './Animations/AnimationTyping'
export { default as AnimationSwapImage } from './Animations/AnimationSwapImage'
export { default as AnimationBubbles } from './Animations/AnimationBubbles'
export { default as AnimationNumber } from './Animations/AnimationNumber'

/**
 * Buttons
 */
export { default as BackButton } from './Buttons/BackButton'
export { default as Button } from './Buttons/Button'
export { default as ButtonLink } from './Buttons/ButtonLink'
export { default as ButtonText } from './Buttons/ButtonText'

/**
 * Embed
 */
export { default as Outgrow } from './Embed/Outgrow'

/**
 * Headers
 */
export { default as HeaderHome } from './Headers/HeaderHome'
export { default as Header } from './Headers/Header'
export { default as HeaderBig } from './Headers/HeaderBig'
export { default as HeaderPicture } from './Headers/HeaderPicture'
export { default as HeaderSmall } from './Headers/HeaderSmall'
export { default as HeaderVideo } from './Headers/HeaderVideo'

/**
 * Forms
 */
export { default as InputBasic } from './Inputs/InputBasic'
export { default as InputButton } from './Inputs/InputButton'
export { default as Select } from './Select'
export { default as Calendly } from './Calendly'

/**
 * Layouts
 */
export { default as Layout } from './Layout/Layout'
export { default as LayoutStorybook } from './LayoutStorybook'
export { default as LayoutSubscription } from './Layout/LayoutSubscription'
export { default as LayoutWithoutHead } from './Layout/LayoutWithoutHead'
export { default as Seo } from './Layout/Seo'

/**
 * List
 */
export { default as BulletList } from './Lists/BulletList'
export { default as CheckList } from './Lists/CheckList'
export { default as CrossList } from './Lists/CrossList'

/**
 * Navigation
 */
export { default as Navigation } from './Navigation/Navigation'
export { default as NavigationSubscription } from './Navigation/NavigationSubscription'

/**
 * Sections
 */
export { default as Section } from './Sections/Section'
export { default as AutomatedSection } from './Sections/AutomatedSection'

/**
 * Sliders
 */

// SliderCard
export { default as SliderCard } from './Sliders/SliderCard/SliderCard'
export { default as SliderCardSlide } from './Sliders/SliderCard/SliderCardSlide'

// SliderLogo
export { default as SliderLogo } from './Sliders/SliderLogo/SliderLogo'

// SliderFeature
export { default as SliderFeature } from './Sliders/SliderFeature/SliderFeature'
export { default as SliderFeatureSlide } from './Sliders/SliderFeature/SliderFeatureSlide'

// SliderList
export { default as SliderList } from './Sliders/SliderList/SliderList'
export { default as SliderListSlide } from './Sliders/SliderList/SliderListSlide'

// SliderTestimony
export { default as SliderTestimony } from './Sliders/SliderTestimony/SliderTestimony'
export { default as SliderTestimonyCompact } from './Sliders/SliderTestimony/SliderTestimonyCompact'
export { default as SliderTestimonySlide } from './Sliders/SliderTestimony/SliderTestimonySlide'

/**
 * Table
 */
export { default as Table } from './Table/Table'

/**
 * Texts
 */
export { default as ExtraBigTitle } from './Texts/ExtraBigTitle'
export { default as BigTitle } from './Texts/BigTitle'
export { default as HandWritten } from './Texts/HandWritten'
export { default as MiddleTitle } from './Texts/MiddleTitle'
export { default as SmallTitle } from './Texts/SmallTitle'
export { default as Subtitle } from './Texts/Subtitle'
export { default as Text } from './Texts/Text'

/**
 * Testimonial
 */
export { default as Testimonials } from './Testimonials'
export { default as Testimonial } from './Testimonials/Testimonial'
export { default as TestimonialWithPicture } from './Testimonials/TestimonialWithPicture'

/**
 * Wheel
 */
export { default as Wheel } from './Wheel'
export { default as WheelItem } from './Wheel/WheelItem'

/**
 * Blog
 */
export { default as CardPost } from './Blog/CardPosts/CardPost'
export { default as CardPostFeatured } from './Blog/CardPosts/CardPostFeatured'
export { default as CardPostRecommended } from './Blog/CardPosts/CardPostRecommended'
export { default as FavPostsAside } from './Blog/CardCtas/FavPosts'
export { default as FreeTrial } from './Blog/CardCtas/FreeTrial'
export { default as Newsletter } from './Blog/CardCtas/Newsletter'
export { default as Pager } from './Blog/Reusables/Pager'
export { default as Progress } from './Blog/Reusables/Progress'
export { default as PostCover } from './Blog/Reusables/Post/PostCover'
export { default as PostBody } from './Blog/Reusables/Post/PostBody'
export { default as BlogSection } from './Blog/Reusables/Layout/BlogSection'
export { default as BlogGrid } from './Blog/Reusables/Layout/BlogGrid'
export { default as BlogMain } from './Blog/Reusables/Layout/BlogMain'
export { default as PostMain } from './Blog/Reusables/Layout/PostMain'
export { default as Empty } from './Blog/Reusables/Layout/Empty'
export { default as HeaderShadow } from './Blog/Reusables/Layout/HeaderShadow'
export { default as ListCtaBlock } from './Blog/Reusables/Layout/ListCtaBlock'
export { default as BlogPageTitle } from './Blog/Reusables/Layout/BlogPageTitle'
export { default as AuthorWrap } from './Blog/Reusables/Author/AuthorWrap'
export { default as AuthorAvatar } from './Blog/Reusables/Author/AuthorAvatar'
export { default as AuthorName } from './Blog/Reusables/Author/AuthorName'
export { default as TagWrap } from './Blog/Reusables/Tags/TagWrap'
export { default as Tag } from './Blog/Reusables/Tags/Tag'
export { default as TagsFilter } from './Blog/Reusables/Tags/TagsFilter'

export { default as Post } from './Blog/templates/post'
export { default as Posts } from './Blog/templates/posts'
export { default as PostsTag } from './Blog/templates/posts-tag'

/**
 * Inject translations
 */

// TO DO : check this custom components if needed after merge

// export const Header = ({ translations, ...rest }) => (
//   <AscHeader translations={translationsMessages} {...rest} />
// )
// export const HeaderBig = ({ translations, ...rest }) => (
//   <AscHeaderBig translations={translationsMessages} {...rest} />
// )
// export const HeaderVideo = ({ translations, ...rest }) => (
//   <AscHeaderVideo translations={translationsMessages} {...rest} />
// )
// export const HeaderHome = ({
//   translations,
//   hubspotId = process.env.GATSBY_HUBSPOT_ID,
//   ...rest
// }) => (
//   <AscHeaderHome
//     hubspotId={hubspotId}
//     translations={translationsMessages}
//     {...rest}
//   />
// )
// export const HeaderSmall = ({ translations, ...rest }) => (
//   <AscHeaderSmall translations={translationsMessages} {...rest} />
// )
// export const InputButton = ({ translations, ...rest }) => (
//   <AscInputButton translations={translationsMessages} {...rest} />
// )
// export const Newsletter = ({
//   translations,
//   hubspotId = process.env.GATSBY_HUBSPOT_ID,
//   ...rest
// }) => (
//   <AscNewsletter
//     hubspotId={hubspotId}
//     translations={translationsMessages}
//     {...rest}
//   />
// )
