import React, { useState } from 'react'
import styled, { css } from 'styled-components'
import { AdaptiveImage, UserInterface, Svg, Video } from '..'
import { chunk } from '../../utils/array'

/**
 * Style
 */
const VideoPlaceholderWrapper = styled(props => <div {...props} />)`
  margin-top: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: ${props => props.width};
  height: ${props => props.height};
  max-width: 100%;
  background-color: ${UserInterface.colors.blue};
  cursor: pointer;
`

const BackgroundImage = styled(props => <AdaptiveImage {...props} />)`
  width: 100%;
  img {
    display: block;
    width: 100%;
  }
`

const PlayButton = styled(Svg)`
  svg {
    position: absolute;
    top: 35%;
    left: 50%;
    transform: translateX(-50%);
    padding-bottom: 25px;
    width: 20%;
    height: auto;
  }
  svg:hover path {
    opacity: 0.95;
  }
  path:first-child {
    transition: 0.3s;
    opacity: 0.75;
  }
  path:nth-child(2) {
    fill: #fff;
  }
`
const TitleStyled = css`
  display: flex;
  position: absolute;
  width: 100%;
  margin: 0;
  color: #fff;
  font-family: ${UserInterface.fonts.family.roboto};
  font-size: 1.5em;
  font-weight: 600;
  justify-content: center;
  text-align: center;
  @media screen and (max-width: 768px) {
    font-size: 0.8em;
  }
`
const TitleStart = styled.p`
  ${TitleStyled}
  top: 65%;
`
const TitleEnd = styled.p`
  ${TitleStyled}
  top: 75%;
`

/**
 * Component
 */
const VideoPlaceholder = ({
  videoId,
  videoTitle = '',
  autoplay = true,
  placeholderSrc = 'components/video/video-placeholder',
  width = '100%',
  height = 'auto',
}) => {
  const [videoVisible, setVideoVisible] = useState(false)

  /**
   * Split video title into two parts, each with an equal number of words.
   * @param {string} title - The title of the video.
   */
  const getTitleParts = title => {
    if (!title) return null
    const titleWords = title.split(' ')
    return chunk(titleWords, Math.ceil(titleWords.length / 2))
  }

  /**
   * Handle click events on VideoPlaceholderWrapper component.
   * Loads the Wistia script if it isn't already loaded.
   */
  const handleVideoPlaceholderWrapperClick = () => {
    const existingScript = document.getElementById('wistia')

    if (!existingScript) {
      const wistiaScript = document.createElement('script')
      wistiaScript.src = 'https://fast.wistia.com/assets/external/E-v1.js'
      wistiaScript.id = 'wistia'
      document.body.appendChild(wistiaScript)
    }

    setVideoVisible(true)
  }

  const titleParts = getTitleParts(videoTitle)

  return (
    <>
      {!videoVisible ? (
        <VideoPlaceholderWrapper
          onClick={handleVideoPlaceholderWrapperClick}
          width={width}
          height={height}
        >
          <BackgroundImage src={placeholderSrc} />
          <PlayButton src="components/video-placeholder/play-button" />
          {titleParts && <TitleStart>{titleParts[0].join(' ')}</TitleStart>}
          {titleParts && titleParts.length >= 2 && (
            <TitleEnd>{titleParts[1].join(' ')}</TitleEnd>
          )}
        </VideoPlaceholderWrapper>
      ) : (
        <Video scriptLoadedFromClick videoId={videoId} autoplay={autoplay} />
      )}
    </>
  )
}

export default VideoPlaceholder
