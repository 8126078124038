import React from 'react'
import { login, subscription } from './links'
import { ESubscription } from './type'

const defaultNavigationFR = {
  navigationButtons: [login, subscription(ESubscription.SERENITE)],
  items: [
    {
      text: 'Votre besoin',
      boxes: [
        {
          children: [
            {
              text: 'Gérer mon asso de A à Z',
              icon: 'common/icon/multicolor/members',
              href: '/tarifs/offre-serenite/',
            },
            {
              text: 'Simplifier ma comptabilité',
              icon: 'common/icon/multicolor/accounting',
              href: '/tarifs/offre-comptabilite/',
            },
            {
              text: 'BabyAsso : créer mon asso',
              icon: 'common/icon/multicolor/generosity',
              href: '/creer-association/',
            },
          ],
        },
        {
          children: [
            {
              text: "Gratuit : collecter de l'argent",
              icon: 'common/icon/multicolor/donations',
              href: '/gratuit/',
            },
            {
              text: 'Structurer mon réseau',
              icon: 'common/icon/multicolor/network',
              href: '/reseaux/',
            },
            {
              text: 'Nouveau : Compte pro et cartes',
              icon: 'common/icon/multicolor/cashflow',
              href: '/compte-pro/',
            },
          ],
        },
      ],
      footer: {
        text: (
          <>
            <span role="img" aria-label="stars">
              ✨
            </span>{' '}
            Nouveau : récupérer de l’argent avec le lien de paiement
          </>
        ),
        href: '/fonctionnalites/lien-de-paiement/',
      },
      width: 600,
    },
    { text: 'Tarifs', href: '/tarifs/' },
    {
      text: 'Ressources',
      href: '/ressources/',
      boxes: [
        {
          children: [
            {
              text: 'Témoignages',
              href: '/blog/tag/t%C3%A9moignages/',
            },
            {
              text: 'Guides gratuits',
              href: '/ressources/guides-associations/',
            },
            {
              text: 'Articles et conseils',
              href: '/blog/',
            },
            {
              text: 'Webinaires',
              href: '/ressources/formations-en-ligne/',
            },
            {
              text: 'Formations',
              href: '/services/',
            },
          ],
        },
      ],
      footer: {
        text: <>💌 Newsletter </>,
        href: '/ressources/newsletter/',
      },
      width: 200,
    },
    { text: 'Notre engagement', href: '/qui-sommes-nous/' },
  ],
}

export default defaultNavigationFR
