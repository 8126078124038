import React, { useState } from 'react'
import styled from 'styled-components'
import { UserInterface, Svg, Flex, Link } from '..'
import AnimateHeight from 'react-animate-height'

/**
 * Style
 */
const NavigationItemStyled = styled.div`
  display: inline-block;
  position: relative;

  /* Show children */
  &:hover .navigation__children {
    visibility: visible;
    opacity: 1;
  }
  /* Mobile mode */
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    display: block;
    text-align: center;
    margin-top: 40px;
    margin-bottom: 15px;

    &:first-child {
      margin-top: 65px;
    }
  }
`
const NavigationItemInner = styled.div`
  display: inline-block;
  font-family: ${UserInterface.fonts.family.roboto};
  font-weight: ${UserInterface.fonts.weight.light};
  font-size: 16px;
  margin: 0 26px;
  cursor: pointer;
  letter-spacing: 0.02px;
  color: ${UserInterface.colors.white};
  transition: all linear 0.2s;
  opacity: 0.7;
  text-align: left;

  &:hover {
    opacity: 1;

    .navigation__arrow {
      opacity: 1;
    }
  }
  /* Mobile mode */
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    font-weight: ${UserInterface.fonts.weight.normal};
    display: flex;
    flex-direction: row !important; /* Force row direction in mobile mode */
    align-items: center;
    margin: auto;
    width: 260px;
    font-size: 17px;
    padding-bottom: 15px;
  }
`
const NavigationItemArrow = styled(props => <Svg {...props} />)`
  display: inline-block;
  margin-left: 10px;
  transition: all linear 0.4s, rotate 0s;
  opacity: 0.7;
  transform: rotate(90deg);

  .svg--color-primary {
    transition: fill linear 0.2s;
  }
  /* Mobile mode */
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    margin-left: auto;
  }
`
const NavigationChildren = styled(props => <AnimateHeight {...props} />)`
  /* Desktop mode */
  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    position: absolute;
    top: 16px;
    padding-top: 14px;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease-out, left 0s;
    display: block !important; /* Ignore the display none of the SlideToggle component */
    width: ${({ width }) => width}px;
    left: -${({ width }) => width / 3.8}px;
  }
  /* Mobile mode */
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    box-shadow: inset 0 1px 16px 0 #dedede;
    background-color: ${UserInterface.colors.extraLightGrey};
  }
`
const NavigationChildrenInner = styled(props => <Flex {...props} />)`
  /* Desktop mode */
  @media screen and (min-width: ${UserInterface.breakpoints.mobile}) {
    background-color: ${UserInterface.colors.white};
    border-radius: 10px;
    white-space: nowrap;
    position: relative;
    box-shadow: ${UserInterface.shadows.slider};
    flex-wrap: wrap;

    &:before {
      top: -6px;
      left: 45%;
      margin-left: -10px;
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 15px 10px;
      border-color: transparent transparent ${UserInterface.colors.white}
        transparent;
      content: ' ';
    }
  }
  /* Mobile mode */
  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    padding-bottom: 22px;
  }
`

/**
 * Component
 */
const NavigationItem = ({
  href,
  text,
  children,
  color,
  width,
  navigationMobileIsOpened,
  nofollow,
}) => {
  const [height, setHeight] = useState(0)

  const toggle = () => {
    if (navigationMobileIsOpened) {
      setHeight(prevHeight => (prevHeight === 0 ? 'auto' : 0))
    }
  }

  return (
    <NavigationItemStyled color={color}>
      <NavigationItemInner className="navigation__inner" onClick={toggle}>
        {href ? (
          <Link href={href} isStyled={false} nofollow={nofollow}>
            {text}
          </Link>
        ) : (
          text
        )}
        {children && (
          <NavigationItemArrow
            height="10px"
            width="10px"
            color="white"
            className="navigation__arrow"
            src="common/icon/unicolor/arrow"
            visibleByDefault
          />
        )}
      </NavigationItemInner>
      {children && (
        <NavigationChildren
          height={navigationMobileIsOpened ? height : 'auto'}
          duration={300}
          width={width}
          className="navigation__children"
        >
          <NavigationChildrenInner py="5px">{children}</NavigationChildrenInner>
        </NavigationChildren>
      )}
    </NavigationItemStyled>
  )
}

export default NavigationItem
