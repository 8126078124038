const translations = {
  fr_FR: {
    inputbutton_validation_required: 'Veuillez indiquer une adresse email.',
    inputbutton_validation_matches:
      'Cette adresse email contient des caractères invalides.',
    inputbutton_validation_email: 'Cette adresse email est incorrecte.',
    inputbutton_consent:
      "J'autorise AssoConnect à enregistrer et utiliser mes données personnelles.",
    inputbutton_placeholder: 'Votre adresse email',
    inputbutton_text: 'Commencer gratuitement',
    videobutton_text: 'Voir la vidéo',
    footer_copyright: 'AssoConnect est conçu avec 💙 à Paris',
    footer_ready: 'Alors, vous vous lancez ?',
    footer_cookies: 'Paramétrer les cookies',
    site_name: 'assoconnect',
    site_name_human: 'AssoConnect',
    site_url: 'https://www.assoconnect.com/',
    site_domain: 'assoconnect.com',
    navigation_login: 'Se connecter',
    tagsfilter_text: 'Filtrer par catégorie',
    tagsfilter_all: 'Tous',
    phone_number: '',
    backButton: 'Retour',
    subscriptionChangeTitle: "Changement d'offre",
    pricingLink: 'https://app.assoconnect.com/sign-up/first/?subscription=',
    pricingText: 'Découvrir AssoConnect',
    pricingLinkQuote: 'https://www.assoconnect.com/reseaux/contact/',
    pricingButton: 'Tester gratuitement',
    pricingButtonFree: 'Créer une plateforme gratuite',
    pricingButtonQuote: 'Contactez-nous',
    pricingButtonChange: 'Choisir',
    pricingUnavailable: 'Offre non accessible',
    pricingCurrent: 'Offre actuelle',
    pricingContactsFromLabel: 'à partir de',
    pricingContactsExplanation: 'Evolutif selon le nombre de contacts',
    pricingPlanUnavailable:
      "Le passage sur cette offre gratuite n'est pas possible actuellement.",
    createFreePlatform: ' Veuillez créer une ',
    createFreePlatformLinkText: 'plateforme gratuite.',
    createFreePlatformLink:
      'https://app.assoconnect.com/sign-up/first?subscription=LIBERTE',
    pricingPlanDeleteAdvancedGroups:
      'Merci de supprimer vos groupes avancés au préalable.',
    post: {
      sidebarCtaTitle: 'Notre guide gratuit pour vous',
      newsletterTitle: 'En voilà une Bonne idée !',
      newsletterSubTitle:
        "Chaque mercredi, recevez la newsletter qui inspire votre asso à passer à l'action. Nos 30 000 abonnés ne peuvent plus s’en passer !",
    },
    pager: {
      next: 'Suivant',
      page: 'Page',
      previous: 'Précédent',
    },
    favorites: { prefix: 'Articles coup de', suffix: '' },
    inputbutton_placeholder_blog: 'Entrez votre adresse email',
    inputbuttonNewsletter_text: 'À mon tour !',
    recommendedTitle: 'Articles recommandés',
    readTimeMinutes: 'min',
    testimonialMembers: 'membres',
    testimonialRead: 'Lire le témoignage',
    learnMore: 'En savoir plus',
    posts: 'Articles',
    adress: 'Adresse',
  },
  en_US: {
    pricingButton: 'Try for free',
    pricingButtonFree: 'Get started',
    subscriptionChangeTitle: 'Subscription update',
    pricingLink: 'https://app.springly.org/sign-up/first/?subscription=',
    pricingLinkQuote: '/en-us/networks/',
    pricingButtonQuote: 'Discover',
    pricingButtonChange: 'Choose',
    pricingUnavailable: 'Unavailable offer',
    pricingContactsFromLabel: 'Start at',
    pricingPlanUnavailable:
      'Taking this free offer is not currently possible. Please create a ',
    pricingPlanUnavailableLinkText: 'free platform.',
    pricingPlanUnavailableLink:
      'https://app.springly.org/sign-up/first/?subscription=LIBERTY_US',
    pricingContactsExplanation: 'Evolving according to the number of contacts',
    inputbutton_validation_required: 'Please fill in an email address.',
    inputbutton_validation_matches:
      'This email address contains invalid characters.',
    inputbutton_validation_email: 'This email address is invalid.',
    inputbutton_consent:
      'I authorize Springly to save and use my personal data.',
    inputbutton_placeholder: 'Email address',
    inputbutton_placeholder_blog: 'Enter your email address',
    inputbutton_text: 'Try Springly',
    videobutton_text: 'Watch the video',
    footer_copyright:
      'Springly was created with 💙 for organizations everywhere',
    footer_ready: 'Ready to get started?',
    footer_cookies: 'Configure cookies',
    site_name: 'springly',
    site_name_human: 'Springly',
    site_url: 'https://www.springly.org/en-us/',
    site_domain: 'springly.org',
    navigation_login: 'Login',
    buttontext_learnmore: 'Learn more',
    prefix_blog: 'en-us/blog',
    inputbuttonNewsletter_text: 'Subscribe',
    tagsfilter_text: 'Filter by category',
    tagsfilter_all: 'All',
    phone_number: '',
    pricingText: 'Discover Springly',
    post: {
      sidebarCtaTitle: '14 Day Free Trial',
      newsletterTitle: 'The Holy Grail of Nonprofit Tips ✨',
      newsletterSubTitle:
        'Get all of the information you need to efficiently manage your nonprofit with our monthly newsletter.',
    },
    pager: {
      next: 'Next',
      page: 'Page',
      previous: 'Previous',
    },
    favorites: { prefix: 'Most', suffix: 'articles' },
    readTimeMinutes: 'min read',
    testimonialMembers: 'members',
    testimonialRead: 'Read the testimonial',
    learnMore: 'Learn more',
    posts: 'Posts',
    adress: 'Address',
  },
}

export default translations
