export interface ICards {
  children: React.ReactElement
  titles: string[]
  type?: ECardsContainerType
}

export interface ICardMulti {
  children: React.ReactElement
  title: string
  image: string
  alt?: string
}

export enum ECardsContainerType {
  LARGE_CARDS = 'largeCards',
  CARDS = 'cards',
}
