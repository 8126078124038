import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import { Navigation } from '../'
import Footer from '../Footer'
import { formatMessage } from '../../utils/translations'
import { Script } from 'gatsby'
import { useLocation } from '@reach/router'
import { getCanonical } from '../../utils/context'

import {
  getBrand,
  getLang,
  getTranslations,
  getLocale,
  getHeaderItems,
} from '../../utils/context'

import {
  footerSocials,
  footerColumns,
  footerCopyrights,
  defaultFooterCta,
  snippets,
} from './brandProps'

/**
 * Component
 */
const Layout = ({
  hreflangs = [],
  customCanonical,
  children,
  meta: metaData,
  noindex = false,
  preFooter,
  headerBackgroundColor = 'blue',
  isBlog = false,
  homePath,
  footerBackground = 'clearBlue',
  partnershipLink,
  header,
  ...props
}) => {
  const translations = getTranslations()

  const brand = getBrand()

  const location = useLocation()

  useEffect(() => {
    // Ensure window is defined and dataLayer exists
    if (typeof window !== 'undefined') {
      window.dataLayer = window.dataLayer || []
      window.dataLayer.push({
        'page-viewed': metaData?.title,
      })
      if (window.rudderanalytics) {
        window.rudderanalytics.page()
      }
    }
  }, [metaData?.title])

  const getMetaImage = image => {
    if (image.search('http') !== -1) {
      return image
    }
    return `${process.env.GATSBY_CDN_HOST}/${image}.png`
  }

  if (brand === undefined) {
    return <></>
  }

  return (
    <>
      <Script id="rudder" async>
        {`
        !function(){"use strict";window.RudderSnippetVersion="3.0.32";var e="rudderanalytics";window[e]||(window[e]=[])
          ;var rudderanalytics=window[e];if(Array.isArray(rudderanalytics)){
          if(true===rudderanalytics.snippetExecuted&&window.console&&console.error){
          console.error("RudderStack JavaScript SDK snippet included more than once.")}else{rudderanalytics.snippetExecuted=true,
          window.rudderAnalyticsBuildType="legacy";var sdkBaseUrl="https://cdn.rudderlabs.com/v3";var sdkName="rsa.min.js"
          ;var scriptLoadingMode="async"
          ;var r=["setDefaultInstanceKey","load","ready","page","track","identify","alias","group","reset","setAnonymousId","startSession","endSession","consent"]
          ;for(var n=0;n<r.length;n++){var t=r[n];rudderanalytics[t]=function(r){return function(){var n
          ;Array.isArray(window[e])?rudderanalytics.push([r].concat(Array.prototype.slice.call(arguments))):null===(n=window[e][r])||void 0===n||n.apply(window[e],arguments)
          }}(t)}try{
          new Function('class Test{field=()=>{};test({prop=[]}={}){return prop?(prop?.property??[...prop]):import("");}}'),
          window.rudderAnalyticsBuildType="modern"}catch(o){}var d=document.head||document.getElementsByTagName("head")[0]
          ;var i=document.body||document.getElementsByTagName("body")[0];window.rudderAnalyticsAddScript=function(e,r,n){
          var t=document.createElement("script");t.src=e,t.setAttribute("data-loader","RS_JS_SDK"),r&&n&&t.setAttribute(r,n),
          "async"===scriptLoadingMode?t.async=true:"defer"===scriptLoadingMode&&(t.defer=true),
          d?d.insertBefore(t,d.firstChild):i.insertBefore(t,i.firstChild)},window.rudderAnalyticsMount=function(){!function(){
          if("undefined"==typeof globalThis){var e;var r=function getGlobal(){
          return"undefined"!=typeof self?self:"undefined"!=typeof window?window:null}();r&&Object.defineProperty(r,"globalThis",{
          value:r,configurable:true})}
          }(),window.rudderAnalyticsAddScript("".concat(sdkBaseUrl,"/").concat(window.rudderAnalyticsBuildType,"/").concat(sdkName),"data-rsa-write-key","1skR1Bt9WGv4v8zaeQolrWm6Qb9")
          },
          "undefined"==typeof Promise||"undefined"==typeof globalThis?window.rudderAnalyticsAddScript("https://polyfill-fastly.io/v3/polyfill.min.js?version=3.111.0&features=Symbol%2CPromise&callback=rudderAnalyticsMount"):window.rudderAnalyticsMount()
          ;var loadOptions={};rudderanalytics.load("${process.env.GATSBY_RUDDERSTACK_KEY}","${process.env.GATSBY_RUDDERSTACK_HOST}",loadOptions)}}}();
      `}
      </Script>

      <Script id="gtm" async>
        {`
        (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.defer=true;j.src=
        'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${process.env.GATSBY_GOOGLE_TAGMANAGER_ID}');
      `}
      </Script>
      <Script id="axeptio" async>
        {/* TO DO: Suffix à retirer en Avril 2025: https://assoconnect.slack.com/archives/C7MSK557A/p1710165386103879?thread_ts=1709752424.741269&cid=C7MSK557A */}
        {`
          window.axeptioSettings = {
            clientId:"${process.env.GATSBY_AXEPTIO_CLIENT_ID}",
            cookiesVersion: "${process.env.GATSBY_AXEPTIO_COOKIES_VERSION}",
            jsonCookieName: "axeptio_cookies_2",
            authorizedVendorsCookieName: "axeptio_authorized_vendors_2",
            allVendorsCookieName: "axeptio_all_vendors_2",
            googleConsentMode: {
              default: [
                {
                  analytics_storage: 'denied',
                  ad_storage: 'denied',
                  ad_user_data: 'denied',
                  ad_personalization: 'denied',
                  wait_for_update: 500,
                },
                {
                  region: ['US'],
                  analytics_storage: 'granted',
                  ad_storage: 'granted',
                  ad_user_data: 'granted',
                  ad_personalization: 'granted',
                  wait_for_update: 500,
                }
              ]
            }
          };
          (function(d, s) {
            var t = d.getElementsByTagName(s)[0], e = d.createElement(s);
            e.async = true; e.src = "//static.axept.io/sdk.js";
            t.parentNode.insertBefore(e, t);
          })(document, "script");
        `}
      </Script>

      <Helmet defer={false}>
        {hreflangs.map((hreflang, i) => (
          <link
            key={i}
            rel="alternate"
            href={hreflang.href}
            hrefLang={hreflang.lang}
          />
        ))}

        <link rel="canonical" href={getCanonical(location, customCanonical)} />

        <meta charSet="utf-8" />
        {noindex && <meta name="robots" content="noindex,nofollow" />}
        {noindex && <meta name="googlebot" content="noindex,nofollow" />}

        <html lang={getLang()} />
        <meta charSet="utf-8" />
        <meta property="og:locale" content={getLocale()} />

        <meta property="og:type" content="website" />

        <title>{metaData.title}</title>
        <meta property="og:title" content={metaData.title} />
        <meta
          property="og:site_name"
          content={formatMessage('site_name_human', translations)}
        />

        <meta name="description" content={metaData.description} />
        <meta property="og:description" content={metaData.description} />

        <meta
          property="og:image"
          content={
            metaData?.image !== undefined
              ? getMetaImage(metaData.image)
              : `${process.env.GATSBY_CDN_HOST}/components/head/${formatMessage(
                  'site_name',
                  translations
                )}-600x600`
          }
        />
        <meta property="og:image:width" content="1200" />
        <meta property="og:image:height" content="628" />

        <link
          rel="preconnect"
          href="https://web-assoconnect-frc-prod-cdn-endpoint-showcase.azureedge.net"
        />
      </Helmet>
      <Helmet defer={true}>
        {props.richSnippets ? (
          <script type="application/ld+json">{props.richSnippets}</script>
        ) : (
          <script type="application/ld+json">{snippets[brand]}</script>
        )}

        <script
          defer
          id="hbspt"
          // eslint-disable-next-line react/no-unknown-property
          charset="utf-8"
          src="https://js.hscta.net/cta/current.js"
        ></script>
      </Helmet>
      {process.env.GATSBY_DEV_SSR !== 'true' && (
        <noscript>
          {`
                <iframe
                  src="https://www.googletagmanager.com/ns.html?id=${process.env.GATSBY_GOOGLE_TAGMANAGER_ID}"
                  height="0"
                  width="0"
                  style="display:none;visibility:hidden"
                ></iframe>
              `}
        </noscript>
      )}

      <Script data-cfasync="false">
        {`
        (function(w,i,s,e){window[w]=window[w]||function(){(window[w].q=window[w].q||[]).push(arguments)};window[w].l=Date.now();s=document.createElement('script');e=document.getElementsByTagName('script')[0];s.defer=1;s.src=i;e.parentNode.insertBefore(s, e)})('wisepops', 'https://wisepops.net/loader.js?v=2&h=${process.env.GATSBY_WISEPOP_ID}');
        `}
      </Script>

      <main>
        <Navigation
          navigationButtons={
            getHeaderItems(header, partnershipLink)['navigationButtons']
          }
          items={getHeaderItems(header, partnershipLink)['items']}
          isBlog={isBlog}
          homePath={homePath ?? '/'}
          headerBackgroundColor={headerBackgroundColor}
          translations={translations}
        />
        {children}
        <Footer
          columns={
            props.footerColumns ? props.footerColumns : footerColumns[brand]
          }
          copyrights={
            props.footerCopyrights
              ? props.footerCopyrights
              : footerCopyrights[brand]
          }
          cta={props.footerCta ?? defaultFooterCta[brand]}
          preFooter={preFooter}
          socials={
            props.footerSocials ? props.footerSocials : footerSocials[brand]
          }
          translations={translations}
          path="/"
          platform={getBrand()}
          footerBackground={footerBackground}
        />
      </main>
    </>
  )
}

export default Layout
