export interface ITestimonials {
  children: React.ReactNode
}
export interface ITestimonial {
  statement: string
  person: {
    name: string
    role: string
  }
  nonprofit: {
    name: string
    description: string
    numberOfMembers: number
  }
  link?: string
  image?: string
  alt: string
}

export interface ITestimonialWithPicture extends ITestimonialTextContent {
  image: string
  imagePosition: EHorizontalPosition
  alt: string
  type?: string
}

export interface ITestimonialTextContent {
  statement: string
  authorName: string
  authorRole: string
  nonprofitName: string
  nonprofitDescription: string
  nonprofitNumberOfMembers: number
  link?: string
}

export enum EHorizontalPosition {
  LEFT = 'LEFT',
  RIGHT = 'RIGHT',
}
