import React, { useState } from 'react'
import styled from 'styled-components'
import AnimateHeight from 'react-animate-height'
import { UserInterface, Svg, Text, Flex, SmallTitle, AdaptiveImage } from '../'

const ExpansionPanelStyled = styled.div`
  background-color: ${UserInterface.colors.extraLightGrey};
  box-shadow: ${UserInterface.shadows.sliderLight};
  border-radius: 10px;
  cursor: pointer;
  align-self: end;
`
const ExpansionPanelArrow = styled(props => (
  <Svg src="common/icon/unicolor/arrow" {...props} />
))`
  transition: 0.3s;

  &.expansion--open {
    transform: rotate(90deg);
  }
`
const ExpansionPanelTitle = styled(props => <SmallTitle {...props} />)`
  text-transform: uppercase;
  flex: 1;
  font-weight: ${UserInterface.fonts.weight.bold};
  margin-left: 10px;

  @media screen and (max-width: ${UserInterface.breakpoints.mobile}) {
    text-align: left !important;
  }
`
const ExpansionPanelTitleWrapper = styled(props => <Flex {...props} />)`
  flex: 1;
`
const ExpansionPanelUl = styled.ul`
  li {
    margin: 10px 0;
  }
`
const ExpansionPanelInner = styled.div`
  padding: 1rem;
`
const ExpansionPanelInnerBorder = styled(props => (
  <ExpansionPanelInner {...props} />
))`
  border-top: 1px dashed ${UserInterface.colors.lightGrey};
`
const ExpansionPanelTextCheck = styled(props => <Text {...props} />)`
  flex: 1;
  margin-left: 10px;
`

/**
 * Component
 */
const TableExpansionPanel = ({
  crossColor,
  column: { title, image, dataIndex },
  datas,
  columnsNumber,
}) => {
  const [height, setHeight] = useState(0)

  const toggle = () => {
    setHeight(prevHeight => (prevHeight === 0 ? 'auto' : 0))
  }

  return (
    <ExpansionPanelStyled
      onClick={toggle}
      className={
        columnsNumber > 6 ? 'hidden-largeTableDesktop' : 'hidden-desktop'
      }
    >
      <ExpansionPanelInner>
        <Flex alignItems="center" custom={false}>
          <ExpansionPanelTitleWrapper alignItems="center" custom={false}>
            <AdaptiveImage src={image} height="50px" />
            <ExpansionPanelTitle color="turquoise" align="left">
              {title}
            </ExpansionPanelTitle>
          </ExpansionPanelTitleWrapper>
          <ExpansionPanelArrow
            width="10px"
            className={height === 0 ? 'expansion--close' : 'expansion--open'}
          />
        </Flex>
      </ExpansionPanelInner>
      <AnimateHeight height={height} duration={300}>
        {datas.map(({ title, children }, i) => (
          <ExpansionPanelInnerBorder key={i}>
            <Text color="middleGrey">
              <strong>{title}</strong>
            </Text>
            <ExpansionPanelUl>
              {children.map((child, j) => {
                const { title, subTitle } = child

                // With specific value
                if (child[dataIndex] === 'no') {
                  return (
                    <li key={j}>
                      <Flex custom={false} alignItems="center">
                        <Svg
                          src="common/icon/unicolor/cross"
                          color={crossColor}
                          width="15px"
                        />
                        <ExpansionPanelTextCheck color="middleGrey">
                          {title || child}
                        </ExpansionPanelTextCheck>
                      </Flex>
                    </li>
                  )
                } else if (child[dataIndex]) {
                  return (
                    <Text key={j} tag="li" color="middleGrey">
                      {title} :{' '}
                      <Text tag="span" color="turquoise">
                        {child[dataIndex]}
                      </Text>
                      <br />
                      <Text size="small" tag="span" color="middleGrey">
                        {subTitle}
                      </Text>
                    </Text>
                  )
                } else {
                  // Without specific value
                  return (
                    <li key={j}>
                      <Flex custom={false} alignItems="center">
                        <Svg
                          src="components/check-list/check"
                          color="turquoise"
                          width="15px"
                        />
                        <ExpansionPanelTextCheck color="middleGrey">
                          {title || child}
                        </ExpansionPanelTextCheck>
                      </Flex>
                    </li>
                  )
                }
              })}
            </ExpansionPanelUl>
          </ExpansionPanelInnerBorder>
        ))}
      </AnimateHeight>
    </ExpansionPanelStyled>
  )
}

export default TableExpansionPanel
